/**
 * @see https://spec.openapis.org/oas/latest.html#components-object
 */
export default class Components {
  /**
   * @type {{}}
   */
  schemas = {};

  constructor(components) {
    this.schemas = components?.schemas;
  }
}
