<template>
  <v-app v-bind:style="{ background: $vuetify.theme.themes.dark.background }">

    <v-app-bar
      app
      color="primary"
      elevate-on-scroll
      scroll-threshold="2"
    >
      <v-text-field
        v-model="searchQuery"
        clearable
        hide-details
        label="Zoeken"
        prepend-inner-icon="mdi-magnify"
        solo-inverted
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs v-model="activeTab" color="accent" dark right>
          <v-tab key="0">API's</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <v-progress-linear
        :active="isLoading"
        absolute
        color="accent"
        indeterminate
        query
        top
        aria-hidden="true"
      ></v-progress-linear>

      <v-container>
        <v-tabs-items v-model="activeTab" class="transparent">

          <v-tab-item key="0">
            <v-subheader class="mb-2">These API's can be used by customers of IB.nl. Contact IB.nl
            for permissions.</v-subheader>
            <v-row>
              <template v-for="(api, k) in filteredApis">
                <v-fade-transition :key="'api-' + k">
                  <v-col cols="12" lg="4" sm="6" xl="3">
                    <v-card light>

                      <v-card-title>{{ api.title }}</v-card-title>

                      <v-chip-group column class="mx-2">
                        <template v-for="(tag, t) in api.tags">
                          <v-chip x-small :key="'tag-' + t">{{ tag }}</v-chip>
                        </template>
                      </v-chip-group>

                      <v-card-text>{{ api.description }}</v-card-text>

                      <v-card-actions>
                        <template v-for="(server, s) in api.servers.slice().reverse()">
                          <v-btn
                            :key="'server-' + s"
                            :href="server.url"
                            color="secondary"
                            target="_blank"
                            x-small
                          >
                            <v-icon left x-small>mdi-open-in-new</v-icon>
                            <span>{{ server.description }}</span>
                          </v-btn>
                        </template>
                      </v-card-actions>

                    </v-card>
                  </v-col>
                </v-fade-transition>
              </template>
            </v-row>
          </v-tab-item>

        </v-tabs-items>
      </v-container>
    </v-main>

    <AppMessage></AppMessage>

  </v-app>
</template>

<script>
import AppMessage from '@/components/layout/AppMessage.vue';

export default {
  name: 'App',

  components: { AppMessage },

  data() {
    return {
      isLoading: false,
      searchQuery: '',
      activeTab: 0,
    };
  },

  computed: {
    filteredApis() {
      const { searchQuery } = this;

      return this.$store.getters['openApi/apiInfo'].filter((api) => {
        if (searchQuery === null || searchQuery.length === 0) {
          return true;
        }

        const title = (api.title ?? '').toLowerCase();
        const matchTitle = title.includes(searchQuery.toLowerCase());
        const description = (api.description ?? '').toLowerCase();
        const matchDescription = description.includes(searchQuery.toLowerCase());
        const tags = (api.tags ?? []).map((tag) => tag.toLowerCase());
        const matchTags = tags.some((tag) => tag.includes(searchQuery.toLowerCase()));

        return matchTags || matchTitle || matchDescription;
      });
    },
  },

  async created() {
    try {
      this.isLoading = true;

      // load all API docs
      await Promise.all([
        this.$store.dispatch('openApi/loadAPISpecification', `${process.env.VUE_APP_LOGIN_API_URL}/docs.jsonopenapi`),
        this.$store.dispatch('openApi/loadAPISpecification', `${process.env.VUE_APP_COMPANY_API_URL}/docs.jsonopenapi`),
        this.$store.dispatch('openApi/loadAPISpecification', `${process.env.VUE_APP_ARTICLE_MANAGER_API_URL}/docs.jsonopenapi`),
        this.$store.dispatch('openApi/loadAPISpecification', `${process.env.VUE_APP_SKU_GENERATOR_API_URL}/docs.json`),
        this.$store.dispatch('openApi/loadAPISpecification', `${process.env.VUE_APP_PARTNER_RESOURCE_API_URL}/docs.jsonopenapi`),
      ]);
    } catch (error) {
      this.$store.commit('messages/add', 'Fout: Niet alle documentatie is geladen.');
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style>
main {
  background: url('~@/assets/logo/logo-inverted.png') no-repeat right bottom;
  background-position-x: calc(100% - 25px);
  background-position-y: calc(100% - 25px);
}
</style>
