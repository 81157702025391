import OpenApi from '@/services/openapi/model/OpenApi';

/**
 * Fetch the API specification and return the OpenApi object.
 *
 * @param {string} url the specification URL.
 * @return {Promise<OpenApi>}
 */
async function fetchAPISpecification(url) {
  const headers = new Headers();
  headers.set('Accept', 'application/json, application/vnd.openapi+json');
  const response = await fetch(url, { headers });
  const data = await response.json();

  return new OpenApi(data);
}

/**
 * Map an array of OpenApi objects to simple objects with basic information.
 *
 * @param {array<OpenApi>} apis
 * @return {[{title: string, description: string, tags: array<string>, servers: *[]}]}
 */
function apiInfo(apis) {
  return apis.map((spec) => {
    const { title, version, description } = spec.info;
    const shortDescription = description.split('.')[0];
    const servers = spec?.servers;
    const tags = [title, version, `OpenAPI ${spec.openapi}`];
    if (spec?.components?.schemas !== undefined) {
      const schemas = Object.keys(spec.components.schemas)
        .map((schemaName) => schemaName.replace('-', ' '));
      tags.push(...schemas);
    }

    return {
      title,
      description: shortDescription,
      tags,
      servers, // TODO also get the versions
    };
  });
}

export {
  OpenApi,
  fetchAPISpecification,
  apiInfo,
};
