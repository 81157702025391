/**
 * Messages store module.
 *
 * This module is used by the AppMessage layout component to display the message.
 * To add a message to queue, you need to commit the add mutation.
 *
 * this.$store.commit('messages/add', 'Hello, World!');
 */
export default {
  namespaced: true,

  state: () => ({
    /**
     * The messages queue
     *
     * @type {string[]}
     */
    queue: [],
  }),

  mutations: {
    /**
     * Add a new message to the queue.
     *
     * @param state
     * @param {string} message
     * @return {void}
     */
    add(state, message) {
      if (typeof message !== 'string') {
        return;
      }

      state.queue.push(message);
    },

    /**
     * Remove the first message from the queue.
     *
     * @param state
     * @return {void}
     */
    removeFirst(state) {
      state.queue.shift();
    },
  },
};
