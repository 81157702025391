/**
 * @see https://spec.openapis.org/oas/latest.html#server-object
 */
export default class Server {
  /**
   * @type {string}
   */
  url;

  /**
   * @type {string}
   */
  description;

  constructor(server) {
    this.url = server?.url;
    this.description = server?.description;
  }
}
