import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import store from './store';

import(/* webpackChunkName: "fonts" */ 'roboto-fontface/css/roboto/roboto-fontface.css');
import(/* webpackChunkName: "fonts" */ '@mdi/font/css/materialdesignicons.css');

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
