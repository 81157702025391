import Info from '@/services/openapi/model/Info';
import Server from '@/services/openapi/model/Server';
import Components from '@/services/openapi/model/Components';

/**
 * @see https://spec.openapis.org/oas/latest.html#openapi-object
 */
export default class OpenApi {
  /**
   * @type {string}
   */
  openapi;

  /**
   * @type {Info}
   */
  info;

  /**
   * @type {Components}
   */
  components;

  /**
   * @type {array<Server>}
   */
  servers = [];

  constructor(openApi) {
    this.openapi = openApi?.openapi;
    this.info = new Info(openApi?.info || {});
    this.components = new Components(openApi?.components || {});

    if (Array.isArray(openApi?.servers) === true) {
      openApi.servers.forEach((spec) => this.servers.push(new Server(spec)));
    }
  }
}
