import Contact from '@/services/openapi/model/Contact';

/**
 * @see https://spec.openapis.org/oas/latest.html#info-object
 */
export default class Info {
  /**
   * @type {string}
   */
  title;

  /**
   * @type {string}
   */
  description;

  /**
   * @type {string}
   */
  termsOfService;

  /**
   * @type {Contact}
   */
  contact;

  /**
   * @type {string}
   */
  version;

  constructor(info) {
    this.title = info?.title;
    this.description = info?.description;
    this.termsOfService = info?.termsOfService;
    this.contact = new Contact(info?.contact || {});
    this.version = info?.version;
  }
}
