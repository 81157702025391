<template>
  <v-snackbar
    v-model="hasMessage"
    app
    bottom
    left
  >{{ message }}</v-snackbar>
</template>

<script>
/**
 * App message component.
 *
 * This component will display all messages in the messages queue.
 */
export default {
  name: 'AppMessage',

  data() {
    return {
      // true when there are messages in the queue
      hasMessage: false,
      // the first message in the queue
      message: null,
    };
  },

  computed: {
    /**
     * @return {string[]}
     */
    messages() {
      return this.$store.state.messages.queue;
    },
  },

  watch: {
    /**
     * Called when message queue changes.
     *
     * @return {void}
     */
    messages() {
      this.displayMessage();
    },

    /**
     * Called when hasMessage state changes.
     *
     * Remove the displayed message from the queue when the snackbar closes.
     *
     * @return {void}
     */
    hasMessage() {
      if (this.hasMessage === false) {
        // this watcher is called when the snackbar closes, not when it is closed
        // use a timeout so the transition can finish
        setTimeout(() => this.$store.commit('messages/removeFirst'), 500);
      }
    },
  },

  mounted() {
    this.displayMessage();
  },

  methods: {
    /**
     * If present, display the first message in the queue.
     *
     * @return {void}
     */
    displayMessage() {
      const { messages } = this;

      if (messages.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.message = messages[0];
        this.hasMessage = true;
      }
    },
  },
};
</script>
