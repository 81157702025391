/**
 * @see https://spec.openapis.org/oas/latest.html#contact-object
 */
export default class Contact {
  /**
   * @type {string}
   */
  name;

  /**
   * @type {string}
   */
  url;

  /**
   * @type {string}
   */
  email;

  constructor(contact) {
    this.name = contact?.name;
    this.url = contact?.url;
    this.email = contact?.email;
  }
}
