import Vue from 'vue';
import { apiInfo, fetchAPISpecification } from '@/services/openapi';

export default {
  namespaced: true,

  state: () => ({
    /**
     * @type {{url: string, spec: OpenApi}}
     */
    specifications: {},
  }),

  mutations: {
    /**
     * @param state
     * @param {url: string, spec: OpenApi}
     */
    UPDATE_API_SPECS(state, { url, spec }) {
      Vue.set(state.specifications, url, spec);
    },
  },

  getters: {
    /**
     * Return basic info of all loaded api specs.
     *
     * @param state
     * @return {{title: string, description: string, tags: Array<string>, servers: *[]}[]}
     */
    apiInfo: (state) => apiInfo(Object.values(state.specifications)),
  },

  actions: {
    /**
     * @param commit
     * @param {string} url
     * @return {Promise<void>}
     */
    async loadAPISpecification({ commit }, url) {
      const apiSpec = new URL(url);
      const spec = await fetchAPISpecification(url);

      commit('UPDATE_API_SPECS', { url: apiSpec.host, spec });
    },
  },
};
